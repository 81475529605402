import React, { useState } from 'react';
import { Wrapper } from './styled';
import { Link, useNavigate } from 'react-router-dom';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import { SignIn } from '../../../service/auth';
import { getMeLogin } from '../../../service/me';
import { useSetRecoilState } from 'recoil';
import { userAtom } from '../../../recoil/users';

const Login = () => {
	//

	const nav = useNavigate();

	const [user_login, set_user_login] = useState('');
	const [user_pw, set_user_pw] = useState('');
	const setUser = useSetRecoilState(userAtom);

	const DoSubmit = () => {
		if (!user_login) {
			toast.error('メールを確認してください');
			return;
		}
		if (!user_pw) {
			toast.error('パスワードを確認してください');
			return;
		}
		SignIn({
			user_login,
			user_pw,
		})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					getMeLogin({
						userToken: res.data.body.user_token,
					})
						.then((response) => {
							if (response.data.code == 200) {
								// Dispatch(SetUserData(res.data.body));
								toast.success('ようこそ!');
								setUser(response.data.body);
								localStorage.setItem('user_token', res.data.body.user_token);
								window.location.href = '/';
							} else {
								toast.error('error');
							}
						})
						.catch((error) => {
							toast.error('error');
						});
				} else {
					toast.error('error');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const DoEnter = (e) => {
		if (e.key == 'Enter') {
			DoSubmit();
		}
	};

	return (
		<>
			<Wrapper>
				<div className="wrapper">
					<div className="top">
						<div className="icon-logo">
							<img src="/images/icon-logo.png" alt="로고" />
						</div>
					</div>
					<div className="login-wrapper">
						<h1>
							SAKURAHUB 社員証
							<br />
							ログイン
						</h1>
						<div className="input-wrap">
							<div className="input-box">
								<input
									onChange={(e) => set_user_login(e.target.value)}
									value={user_login}
									type="text"
									placeholder="ID"
									name="login"
								/>
							</div>
							<div className="input-box">
								<div className="flex"></div>
								<input
									onChange={(e) => set_user_pw(e.target.value)}
									value={user_pw}
									type="password"
									placeholder="Password"
									onKeyDown={DoEnter}
								/>
							</div>
						</div>
						<div className="login-btn" onClick={DoSubmit}>
							ログインする
						</div>
					</div>
				</div>
			</Wrapper>
		</>
	);
};

export default Login;
