import axios from './defaultClient';

const nameSpace = '/api/v1/me';

export const Getme = async (args) => {
	return await axios.get(nameSpace + '/', {});
};

export const getMeLogin = async (args) => {
	if (args.userToken) {
		axios.defaults.headers.common = {
			Authorization: `Bearer ${args.userToken}`,
		};
	}
	return await axios.get(
		'https://wp.sakurahub-member.com/wp-json' + nameSpace,
		{}
	);
};

export const GetUser = async (args) => {
	return await axios.get('/api/v1/users/' + args.ID, {});
};
