import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	transition: 0.2s;
	.overlay {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.35);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		transition: 0.2s;
	}
	.container {
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translate(-50%, 100%);
		max-width: ${(props) => (props.width ? props.width : `500px`)};
		width: 100%;
		height: ${(props) => (props.height ? props.height : `350px`)};
		max-height: ${(props) => (props.maxHeight ? props.maxHeight : ``)};
		z-index: ${(props) => (props.zindex ? props.zindex : `50`)};
		border-radius: 20px 20px 0px 0px;
		background-color: ${(props) => (props.bg ? props.bg : `#fff`)};
		transition: 0.2s;
		overflow: ${(props) => (props.auto ? props.auto : `auto`)};
		padding: ${(props) =>
			props.padding || props.padding === 0
				? props.padding
				: `10px 20px 18px 20px`};
	}
	.close-btn {
		position: absolute;
		top: ${(props) => (props.top ? props.top : `-14px`)};
		right: 0px;
		transform: translateY(-100%);
		cursor: pointer;
		padding: 6px;
	}

	// 홈에서
	${(props) =>
		props.home
			? `
      @media (max-width: 500px) {
      .container {
        max-width: 100vw;
      }
      
    }
    @media (max-width: 460px) {
      .container {
        // max-width: initial;
      }
      
    }

  
  `
			: ``}

	${(props) =>
		props.active
			? `
      opacity: 1;
      visibility: visible;
      transition: 0.2s;
      z-index: 99;
      .overlay {
        opacity: 1;
        visibility: visible;
        z-index: 50;
      }
      .container {
        transform: translate(-50%, 0%);
      }
    `
			: ``}
`;
