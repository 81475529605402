import styled from 'styled-components';

export const Wrapper = styled.div`
	max-width: 500px;
	width: 100%;
	margin: 0 auto;
`;

export const TopWrapper = styled.div`
	.top {
		height: 58px;
		padding: 16px 24px;
	}
	.flex {
		display: flex;
		justify-content: end;
		align-items: center;
	}
`;

export const CardWrapper = styled.div`
	margin-top: 6px;
	padding: 0 20px;
	.card {
		border-radius: 16px;
		border: 1px solid #eee;
		background: #fff;
		padding: 40px 30px 38px 30px;
		background-image: url(/images/bg.png);
		background-repeat: no-repeat;
		background-size: 200px;
		background-position: bottom right 0;
		.logo {
			width: 122px;
			img {
				width: 100%;
			}
		}
		.employee {
			margin-top: 26px;
			display: flex;
			justify-content: space-between;
			.position-box {
				.name {
					display: flex;
				}
				h1 {
					font-size: 26px;
					font-weight: 700;
					line-height: 140%;
				}
				.position {
					margin-top: 12px;
					display: flex;
					gap: 16px;
					p {
						position: relative;
						font-size: 15px;
						font-weight: 500;
						line-height: 150%;
						opacity: 0.6;
						&:after {
							position: absolute;
							content: '';
							width: 1px;
							height: 12px;
							background-color: rgba(0, 0, 0, 0.1);
							right: -6px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					span {
						font-size: 15px;
						font-weight: 500;
						line-height: 150%;
						opacity: 0.6;
					}
				}
			}
			.userimg-box {
				.userimg {
					width: 60px;
					height: 60px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					border-radius: 50%;
				}
			}
		}
		.under {
			margin-top: 95px;
			.address {
				font-size: 14px;
				font-weight: 400;
				line-height: 150%;
				margin-bottom: 14px;
				opacity: 0.6;
			}
			.flex {
				display: flex;
				gap: 10px;
				margin-top: 6px;
				p {
					font-size: 14px;
					font-weight: 500;
					line-height: 150%;
					opacity: 0.4;
				}
				span {
					font-size: 14px;
					font-weight: 500;
					line-height: 150%;
				}
			}
		}
	}
`;

export const SharWrapper = styled.div`
	margin-top: 10px;
	.sharing-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.date {
			display: flex;
			gap: 6px;
			opacity: 0.2;
			p {
				font-size: 13px;
				font-weight: 400;
				line-height: 150%;
			}
			span {
				font-size: 14px;
				font-weight: 400;
				line-height: 150%;
			}
		}
		.sharing {
			display: flex;

			gap: 5px;
			p {
				font-size: 13px;
				font-weight: 400;
				line-height: 150%;
				opacity: 0.4;
				cursor: pointer;
			}
			.shar-img {
				cursor: pointer;
			}
		}
	}
`;

export const BtnWrapper = styled.div`
	margin-top: 20px;
	padding: 0 20px;
	.remain {
		display: flex;
		justify-content: center;
		p {
			color: #fff;
			font-size: 12px;
			font-weight: 500;
			line-height: 150%;
			padding: 5px 14px;
			border-radius: 170px;
			background: rgba(0, 0, 0, 0.3);
		}
	}
	.btn-box {
		margin-top: 10px;
		border-radius: 16px;
		background: #e94f4e;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.04);
		display: flex;
		justify-content: center;
		height: 56px;
		.btn {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			position: relative;
			cursor: pointer;
			&:after {
				position: absolute;
				content: '';
				width: 1px;
				height: 24px;
				background-color: rgba(255, 255, 255, 0.2);
				right: 0;
			}
			img {
				width: 18px;
			}
			p {
				color: #fff;
				font-size: 12px;
				font-weight: 600;
				line-height: 140%;
			}
		}
	}
`;
