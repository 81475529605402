import axios from 'axios';

axios.defaults.withCredentials = true;

const localToken = localStorage.getItem('user_token');

if (localToken) {
	axios.defaults.headers.common = { Authorization: `Bearer ${localToken}` };
}

const baseURL = (() => {
	return 'https://wp.sakurahub-member.com/wp-json';
})();

const defaultClient = axios.create({
	baseURL,
	withCredentials: true,
});

export default defaultClient;
