import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isLogined } from '../../service/auth';
import { getMeLogin } from '../../service/me';
import { useSetRecoilState } from 'recoil';
import { userAtom } from '../../recoil/users';

const AuthContainer = (props) => {
	const [tmp, set_tmp] = useState(false);
	const setUser = useSetRecoilState(userAtom);

	useEffect(() => {
		if (localStorage.getItem('user_token')) {
			isLogined()
				.then((response) => {
					if (response.data.data.status != 200) {
						toast.error('もう一度ログインしてください.');
						localStorage.removeItem('user_token');
						window.location.href = '/login';
					} else {
						set_tmp(true);
						getMeLogin({})
							.then((response) => {
								if (response.data.code == 200) {
									// Dispatch(SetUserData(res.data.body));
									setUser(response.data.body);
								} else {
									toast.error('error');
								}
							})
							.catch((error) => {
								toast.error('error');
							});
					}
				})
				.catch((error) => {
					console.log(error);
					localStorage.removeItem('user_token');
				});
		} else {
			toast.error('ログインしてください.');
			window.location.href = '/login';
		}
	}, []);

	return tmp ? <>{props.children}</> : null;
};

export default AuthContainer;
