import React, { useState } from 'react';
import { Wrapper } from './styled';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const CalendarWrapper = ({
	toggleCalendar,
	date,
	onChange,
	maxDate,
	minDate,
}) => {
	return (
		<Wrapper>
			<div onClick={toggleCalendar} className="overlay"></div>
			<div className="as" onClick={toggleCalendar}>
				<img src="/images/icon-calendar-close.svg" />
			</div>
			<div className="cal">
				<Calendar
					onChange={onChange}
					value={date}
					maxDate={maxDate}
					minDate={minDate}
					formatDay={(locale, date) => moment(date).format('DD')}
					showNeighboringMonth={false}
					navigationLabel={null}
					locale="ja"
				/>
			</div>
		</Wrapper>
	);
};

export default CalendarWrapper;
