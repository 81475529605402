import React, { useEffect, useState } from 'react';
import {
	BtnWrapper,
	CardWrapper,
	SharWrapper,
	TopWrapper,
	Wrapper,
} from './styled';
import CreateAnnua from './CreateAnnualLayer';
import MyAnnualsLayer from './MyAnnualsLayer';
import AuthContainer from '../../components/AuthComtainer';
import { userAtom } from '../../recoil/users';
import { useRecoilValue } from 'recoil';
import { GetAnnuals } from '../../service/annual';
import ShareLayer from './ShareLayer';

const Home = (props) => {
	const [active, setActive] = useState(false);
	const toggle = () => {
		setActive((p) => !p);
	};

	const [historyActive, historysetActive] = useState(false);
	const historyToggle = () => {
		historysetActive((p) => !p);
	};

	const [sharActive, sharsetActive] = useState(false);
	const sharToggle = () => {
		sharsetActive((p) => !p);
	};

	const user = useRecoilValue(userAtom);

	const DoLogout = () => {
		localStorage.clear();
		window.location.href = '/login';
	};

	const [items, setItems] = useState([]);
	useEffect(() => {
		GetAnnuals({
			paged: 1,
			posts_per_page: 100,
			isMine: 1,
		})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					setItems(res.data.body.items);
				} else {
					setItems(null);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [allAnnuals, setAllAnnuals] = useState([]);
	useEffect(() => {
		GetAnnuals({
			paged: 1,
			posts_per_page: 10000,
		})
			.then((res) => {
				console.log(res);
				if (res.data.code == 200) {
					setAllAnnuals(res.data.body.items);
				} else {
					setAllAnnuals(null);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<AuthContainer>
			<Wrapper>
				<TopWrapper>
					<div className="top">
						<div className="flex">
							<div onClick={DoLogout} className="share">
								<img src="/images/icon_share02.svg" alt="공유아이콘" />
							</div>
						</div>
					</div>
				</TopWrapper>
				<CardWrapper>
					<div className="card">
						<div className="logo">
							<img src="/images/main-logo.png" alt="메인로고" />
						</div>
						<div className="employee">
							<div className="position-box">
								<div className="name">
									<h1>{user?.name}</h1>
								</div>
								<div className="position">
									<p>{user?.position}</p>
									<span>{user?.depart}</span>
								</div>
							</div>
							<div className="userimg-box">
								<div
									className="userimg"
									style={{ backgroundImage: `url(${user?.user_avatar})` }}
								></div>
							</div>
						</div>
						<div className="under">
							<p className="address">{user?.address}</p>
							<div className="flex">
								<p>E-mail</p>
								<span>{user?.email}</span>
							</div>
							<div className="flex">
								<p>Tel</p>
								<span>{user?.tel}</span>
							</div>
						</div>
					</div>
					<SharWrapper>
						<div className="sharing-box">
							<div className="date">
								<p>入社日</p>
								<span>{user?.join_date}</span>
							</div>
							<div className="sharing" onClick={sharToggle}>
								<p>共有</p>
								<div className="shar-img">
									<img src="images/icon_share.svg" alt="공유아이콘" />
								</div>
							</div>
						</div>
					</SharWrapper>
				</CardWrapper>
				<BtnWrapper>
					<div className="remain">
						<p>
							残り <span>{user?.annualText}</span>
						</p>
					</div>
					<div className="btn-box">
						<div className="btn" onClick={toggle}>
							<img src="/images/icon-address_book.png" alt="우산아이콘" />
							<p>年次申請</p>
						</div>
						<div className="btn" onClick={historyToggle}>
							<img src="/images/icon-time.png" alt="시계아이콘" />
							<p>年次使用内訳</p>
						</div>
					</div>
				</BtnWrapper>
			</Wrapper>
			<CreateAnnua
				active={active}
				setActive={setActive}
				toggle={toggle}
				annualText={user?.annualText}
				user={user}
			/>
			<MyAnnualsLayer
				active={historyActive}
				historysetActive={historysetActive}
				historyToggle={historyToggle}
				toggle={toggle}
				annualText={user?.annualText}
				items={items}
				allAnnuals={allAnnuals}
			/>
			<ShareLayer active={sharActive} toggle={sharToggle} user={user} />
		</AuthContainer>
	);
};

export default Home;
