import styled from 'styled-components';

export const Wrapper = styled.div`
	.bar {
		width: 36px;
		margin: 0 auto;
		height: 4px;
		border-radius: 20px;
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
	.hlaf-box {
		cursor: pointer;
	}
	.top {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
		.title {
			display: flex;
			gap: 8px;
			img {
				width: 18px;
			}
			p {
				font-size: 15px;
				font-weight: 700;
				line-height: normal;
			}
		}
		span {
			color: #e94f4e;
			font-size: 13px;
			font-weight: 400;
			line-height: normal;
		}
	}
	ul {
		margin-top: 20px;
		display: flex;
		padding: 6px;
		border-radius: 14px;
		border: 1px solid #eee;
		background: #fff;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
		li {
			border-radius: 10px;
			width: 33.3%;
			height: 52px;
			opacity: 0.3;
			cursor: pointer;
			&.active {
				background: #111;
				color: #fff;
				opacity: 1;
			}
			p {
				text-align: center;
				font-size: 13px;
				font-weight: 400;
				line-height: 50px;
			}
		}
	}
	.calendar-box {
		margin-top: 10px;
		border-radius: 14px;
		border: 1px solid #eee;
		background: #fff;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
		display: flex;
		gap: 18px;
		.schedule {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 50%;
			height: 52px;
			padding: 0 18px;
			position: relative;
			p {
				font-size: 13px;
				font-weight: 400;
				opacity: 0.3;
			}
			span {
				color: #000;
				font-size: 13px;
				font-weight: 400;
				line-height: normal;
			}
			img {
				width: 16px;
				height: 16px;
				/* position: absolute; */
				right: 18px;
			}
		}
		.minus {
			width: 9px;
			font-size: 13px;
			font-weight: 500;
			line-height: 50px;
		}
	}
	.hlaf-box {
		margin-top: 10px;
		border-radius: 14px;
		border: 1px solid #eee;
		background: #fff;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
		display: flex;
		gap: 18px;
		.schedule {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 52px;
			padding: 0 18px;
			position: relative;
			p {
				font-size: 13px;
				font-weight: 400;
				opacity: 0.3;
			}
			span {
				color: #000;
				font-size: 13px;
				font-weight: 400;
				line-height: normal;
			}
			img {
				width: 16px;
				height: 16px;
				position: absolute;
				right: 18px;
			}
		}
	}
`;

export const BtnWrapper = styled.div`
	margin-top: 20px;
	.btn {
		height: 56px;
		border-radius: 16px;
		background: #f2f2f2;
		color: #fff;
		text-align: center;
		font-size: 13px;
		font-weight: 600;
		line-height: 54px;
		letter-spacing: -0.26px;
	}
	.active {
		background-color: #000;
	}
`;
