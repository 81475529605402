import BottomSheet from '../../../components/BottomSheet';
import { useState } from 'react';
import { ContentWrapper, Wrapper } from './styled';
import { BtnWrapper } from './styled';
import CalendarWrapper from '../CreateAnnualLayer/Calendar';

const MyAnnualsLayer = ({
	active,
	toggle,
	historyToggle,
	annualText,
	items,
	allAnnuals,
}) => {
	const [tab, setTab] = useState(0);

	return (
		<>
			<BottomSheet
				active={active}
				toggle={historyToggle}
				height={'unset'}
				auto={'hidden'}
			>
				<Wrapper>
					<div className="bar" onClick={historyToggle}></div>
					<div className="top">
						<div className="title">
							<img src="/images/icon-tiem-black.png" alt="시계아이콘" />
							<p>年次使用内訳</p>
						</div>
					</div>
					<div className="tab">
						<ul>
							<li
								onClick={() => setTab(0)}
								className={tab == 0 ? 'active' : ''}
							>
								私の年次
							</li>
							<li
								onClick={() => setTab(1)}
								className={tab == 1 ? 'active' : ''}
							>
								全職員年次
							</li>
						</ul>
					</div>
					{tab == 0 ? (
						<>
							<div className="under">
								<p>残余年次</p>
								<h2>
									<span>🏖️</span>
									{annualText}
								</h2>
							</div>

							<div className="line"></div>
							<ContentWrapper>
								<div className="cont">
									{items?.length
										? items.map((item, key) => (
												<div className="flex" key={key}>
													<div className="left">
														<p>{item.date}</p>
														<span>{item.days}</span>
													</div>
													<div className="right">
														<p>{item.type}</p>
													</div>
												</div>
										  ))
										: null}
								</div>
								<div className="bg"></div>
							</ContentWrapper>
						</>
					) : null}
					{tab == 1 ? (
						<ContentWrapper className="all">
							<div className="cont">
								{allAnnuals?.length
									? allAnnuals.map((item, key) => (
											<div className="flex" key={key}>
												<div className="left">
													<p>{item.date}</p>
													<span>{item.days}</span>
													<span>
														<b>·</b>
														{item.name} ({item.depart}){' '}
													</span>
												</div>
												<div className="right">
													<p>{item.type}</p>
												</div>
											</div>
									  ))
									: null}
							</div>
							<div className="bg"></div>
						</ContentWrapper>
					) : null}
					<BtnWrapper
						onClick={() => {
							historyToggle();
							toggle();
						}}
					>
						<div className="btn">年次申請</div>
					</BtnWrapper>
				</Wrapper>
			</BottomSheet>
		</>
	);
};

export default MyAnnualsLayer;
