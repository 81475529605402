import React, { useEffect, useState } from 'react';
import {
	BtnWrapper,
	CardWrapper,
	SharWrapper,
	TopWrapper,
	Wrapper,
} from './styled';
import AuthContainer from '../../components/AuthComtainer';
import { userAtom } from '../../recoil/users';
import { useRecoilValue } from 'recoil';
import { GetAnnuals } from '../../service/annual';
import { GetUser } from '../../service/me';
import { useParams } from 'react-router-dom';

const Card = (props) => {
	//
	const [user, setUser] = useState({});
	const params = useParams();

	useEffect(() => {
		GetUser({
			ID: params?.ID,
		})
			.then((response) => {
				if (response.data.code == 200) {
					// Dispatch(SetUserData(res.data.body));
					setUser(response.data.body);
				} else {
					// toast.error('error');
				}
			})
			.catch((error) => {
				// toast.error('error');
			});
	}, []);

	return (
		<>
			{user?.ID ? (
				<Wrapper>
					<TopWrapper>
						<div className="top">
							<div className="flex">
								{/* <div onClick={DoLogout} className="share">
								<img src="/images/icon_share02.svg" alt="공유아이콘" />
							</div> */}
							</div>
						</div>
					</TopWrapper>
					<CardWrapper>
						<div className="card">
							<div className="logo">
								<img src="/images/main-logo.png" alt="메인로고" />
							</div>
							<div className="employee">
								<div className="position-box">
									<div className="name">
										<h1>{user?.name}</h1>
									</div>
									<div className="position">
										<p>{user?.position}</p>
										<span>{user?.depart}</span>
									</div>
								</div>
								<div className="userimg-box">
									<div
										className="userimg"
										style={{ backgroundImage: `url(${user?.user_avatar})` }}
									></div>
								</div>
							</div>
							<div className="under">
								<p className="address">{user?.address}</p>
								<div className="flex">
									<p>E-mail</p>
									<span>{user?.email}</span>
								</div>
								<div className="flex">
									<p>Tel</p>
									<span>{user?.tel}</span>
								</div>
							</div>
						</div>
						{/* <SharWrapper>
						<div className="sharing-box">
							<div className="date">
								<p>入社日</p>
								<span>{user?.join_date}</span>
							</div>
							<div className="sharing" onClick={sharToggle}>
								<p>共有</p>
								<div className="shar-img">
									<img src="images/icon_share.svg" alt="공유아이콘" />
								</div>
							</div>
						</div>
					</SharWrapper> */}
					</CardWrapper>
					{/* <BtnWrapper>
					<div className="remain">
						<p>
							残り <span>{user?.annualText}</span>
						</p>
					</div>
					<div className="btn-box">
						<div className="btn" onClick={toggle}>
							<img src="/images/icon-address_book.png" alt="우산아이콘" />
							<p>年次申請</p>
						</div>
						<div className="btn" onClick={historyToggle}>
							<img src="/images/icon-time.png" alt="시계아이콘" />
							<p>年次使用内訳</p>
						</div>
					</div>
				</BtnWrapper> */}
				</Wrapper>
			) : null}
		</>
	);
};

export default Card;
