import axios from './defaultClient';

const nameSpace = '/api/v1/auth';

export const isLogined = async () => {
	return await axios.post('/jwt-auth/v1/token/validate');
};

export const SignIn = async (args) => {
	return await axios.post(nameSpace + '/', args);
};
