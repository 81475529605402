import React from 'react';
import { Wrapper } from './styled';

const BottomSheet = ({
	children,
	active,
	toggle,
	widht,
	height,
	maxHeight,
	padding,
	home,
	auto,
	bg,
	top,
	zindex,
	historyToggle,
	sharToggle,
}) => {
	return (
		<Wrapper
			active={active}
			width={widht}
			height={height}
			maxHeight={maxHeight}
			auto={auto}
			padding={padding}
			home={home}
			bg={bg}
			top={top}
			zindex={zindex}
			historyToggle={historyToggle}
			sharToggle={sharToggle}
		>
			<div className="overlay" onClick={toggle} />
			<div className="container">
				<div className="inner">{children}</div>
			</div>
		</Wrapper>
	);
};

export default BottomSheet;
