import styled from 'styled-components';

export const Wrapper = styled.div`
	.bar {
		width: 36px;
		margin: 0 auto;
		height: 4px;
		border-radius: 20px;
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
	.top {
		margin-top: 18px;
		.title {
			display: flex;
			gap: 8px;
			img {
				width: 18px;
				cursor: pointer;
			}
			p {
				font-size: 15px;
				font-weight: 700;
				line-height: normal;
			}
		}
	}
	.copy-box {
		margin: 20px 0;
		border-radius: 14px;
		padding: 18px;
		border: 1px solid #eee;
		background: #fff;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			font-size: 13px;
			font-weight: 400;
			line-height: normal;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding-right: 10px;
		}
		.copy {
			white-space: nowrap;
			display: flex;
			align-items: center;
			gap: 4px;
			cursor: pointer;
			span {
				font-size: 12px;
				font-weight: 400;
				line-height: 150%;
			}
		}
	}
`;

export const BtnWrapper = styled.div`
	.btn {
		height: 56px;
		border-radius: 16px;
		background: #111;
		color: #fff;
		text-align: center;
		font-size: 13px;
		font-weight: 600;
		line-height: 54px;
		letter-spacing: -0.26px;
		cursor: pointer;
		transition: all 0.3s;
		&:hover {
			opacity: 0.9;
		}
	}
`;
