import styled from 'styled-components';

export const Wrapper = styled.div`
	.bar {
		width: 36px;
		margin: 0 auto;
		height: 4px;
		border-radius: 20px;
		background-color: rgba(0, 0, 0, 0.1);
		cursor: pointer;
	}
	.top {
		margin-top: 18px;
		padding-left: 10px;
		.title {
			display: flex;
			align-items: center;
			gap: 8px;
			img {
				width: 18px;
				height: 18px;
			}
			p {
				font-size: 15px;
				font-weight: 700;
				line-height: normal;
			}
		}
	}
	.tab {
		width: 100vw;
		transform: translateX(-10px);
		padding: 0 20px;
		margin: 24px 0 0 0;
		border-bottom: 1px solid #eee;
		ul {
			display: flex;
			gap: 0 22px;
			li {
				color: rgba(0, 0, 0, 0.3);
				font-family: Pretendard;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.84px;
				padding-bottom: 12px;
				cursor: pointer;
				&.active {
					color: #e94f4e;
					border-bottom: 2px solid #e94f4e;
				}
			}
		}
	}
	.under {
		margin-top: 30px;
		padding-left: 10px;
		p {
			color: #999;
			font-size: 12px;
			font-weight: 400;
			line-height: normal;
		}
		h2 {
			margin-top: 14px;
			font-size: 18px;
			font-weight: 700;
			line-height: normal;
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	.line {
		margin: 24px -20px 28px -20px;
		border-top: 6px solid #f6f6f6;
	}
`;

export const ContentWrapper = styled.div`
	position: relative;
	height: 300px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}

	&.all {
		min-height: 414px;
		margin-top: 28px;
	}
	b {
		margin: 0 6px;
	}
	.flex:last-child .left {
		padding-bottom: 0;
	}
	.flex {
		display: flex;
		justify-content: space-between;
		.left {
			padding-left: 16px;
			margin-left: 10px;
			position: relative;
			padding-bottom: 40px;
			&::before {
				position: absolute;
				content: '';
				top: 4px;
				left: -1px;
				width: 1px;
				height: 100%;
				border-left: 1px solid rgba(0, 0, 0, 0.06);
			}
			&:after {
				position: absolute;
				content: '';
				background-image: url(/images/icon-dot.svg);
				width: 10px;
				height: 10px;
				left: -5px;
				top: 3px;
			}
			p {
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 0.84px;
				margin-bottom: 6px;
			}
			span {
				color: #999;
				font-size: 13px;
				font-weight: 400;
				line-height: normal;
			}
		}
		.right {
			p {
				font-size: 11px;
				font-weight: 500;
				line-height: 22px;
				border-radius: 7px;
				background: rgba(0, 0, 0, 0.06);
				padding: 0 5px 0 6px;
				height: 20px;
			}
		}
	}
	.bg {
		width: 100%;
		height: 60px;
		background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
		position: sticky;
		bottom: 0;
	}
`;

export const BtnWrapper = styled.div`
	.btn {
		height: 56px;
		border-radius: 16px;
		background: #111;
		color: #fff;
		text-align: center;
		font-size: 13px;
		font-weight: 600;
		line-height: 54px;
		letter-spacing: -0.26px;
	}
`;
