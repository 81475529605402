import BottomSheet from '../../../components/BottomSheet';
import { useState } from 'react';
import { Wrapper } from './styled';
import { BtnWrapper } from './styled';
import CalendarWrapper from './Calendar';
import moment from 'moment';
import { InsertAnnual } from '../../../service/annual';
import { toast } from 'react-toastify';

const CreateAnnua = ({ active, toggle, annualText, user }) => {
	//
	const [type, setType] = useState('all');

	const [showStartCal, setShowStartCal] = useState(false);
	const [showEndCal, setShowEndCal] = useState(false);

	const toggleStartCal = () => {
		setShowStartCal((p) => !p);
	};
	const toggleEndCal = () => {
		setShowEndCal((p) => !p);
	};

	const currentDate = new Date();
	const [startDate, setStartDate] = useState('');
	const handlerStartDate = (e) => {
		toggleStartCal();
		setStartDate(e);
	};
	const [endDate, setEndDate] = useState('');
	const handlerEndDate = (e) => {
		toggleEndCal();
		setEndDate(e);
	};

	const Submit = () => {
		if (window.confirm('申し込みますか？')) {
			let diff = 0.5;

			if (type == 'all') {
				const diffInMilliseconds = endDate - startDate;
				diff = diffInMilliseconds / (1000 * 60 * 60 * 24) + 1;
				if (user?.annual < diff) {
					toast.error('残りの年次不足です。');
					return;
				}
			}

			if (type != 'all') {
				if (user?.annual < 0.5) {
					toast.error('残りの年次不足です。');
					return;
				}
			}

			InsertAnnual({
				type: type,
				start_date: moment(startDate).format('YYYY-MM-DD'),
				end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
				diff: diff,
			})
				.then((res) => {
					if (res.data.code == 200) {
						toast.success('申し込みが完了しました.');
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					} else {
						toast.error('error');
					}
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	return (
		<>
			<BottomSheet
				active={active}
				toggle={toggle}
				height={'unset'}
				auto={'hidden'}
			>
				<Wrapper>
					<div className="bar" onClick={toggle}></div>
					<div className="top">
						<div className="title">
							<img src="/images/icon-address_book-black.png" alt="우산아이콘" />
							<p>年次申請</p>
						</div>
						<span>残り {annualText}</span>
					</div>
					<div>
						<ul>
							<li
								className={type === 'all' ? 'active' : ''}
								onClick={() => {
									setType('all');
								}}
							>
								<p>一日</p>
							</li>
							<li
								className={type === 'am' ? 'active' : ''}
								onClick={() => {
									setType('am');
									setEndDate('');
								}}
							>
								<p>半休午前</p>
							</li>
							<li
								className={type === 'pm' ? 'active' : ''}
								onClick={() => {
									setType('pm');
									setEndDate('');
								}}
							>
								<p>半休午後</p>
							</li>
						</ul>
						{type === 'all' ? (
							<div className="calendar-box">
								<div className="schedule" onClick={toggleStartCal}>
									{startDate ? (
										<span>{moment(startDate).format('YYYY.MM.DD')}</span>
									) : (
										<p>スケジュール</p>
									)}
									<img src="/images/icon-calendar.png" alt="달력아이콘" />
								</div>
								<div className="minus">
									<p>~</p>
								</div>
								<div className="schedule" onClick={toggleEndCal}>
									{endDate ? (
										<span>{moment(endDate).format('YYYY.MM.DD')}</span>
									) : (
										<p>スケジュール</p>
									)}
									<img src="/images/icon-calendar.png" alt="달력아이콘" />
								</div>
							</div>
						) : (
							<div className="hlaf-box">
								<div className="schedule" onClick={toggleStartCal}>
									{startDate ? (
										<span>{moment(startDate).format('YYYY.MM.DD')}</span>
									) : (
										<p>スケジュール</p>
									)}
									<img src="/images/icon-calendar.png" alt="달력아이콘" />
								</div>
							</div>
						)}
					</div>
					<BtnWrapper>
						{(type == 'all' && startDate && endDate) ||
						(type != 'all' && startDate) ? (
							<div onClick={Submit} className="btn active">
								年次申請
							</div>
						) : (
							<div className="btn">年次申請</div>
						)}

						<div onClick={() => console.log(typeof new Date())}></div>
					</BtnWrapper>
				</Wrapper>
			</BottomSheet>
			{showStartCal ? (
				<CalendarWrapper
					toggleCalendar={toggleStartCal}
					onChange={handlerStartDate}
					value={startDate}
					minDate={currentDate}
					maxDate={endDate ? endDate : null}
				/>
			) : null}
			{showEndCal ? (
				<CalendarWrapper
					toggleCalendar={toggleEndCal}
					onChange={handlerEndDate}
					value={endDate}
					minDate={startDate ? startDate : null}
				/>
			) : null}
		</>
	);
};

export default CreateAnnua;
