import styled from 'styled-components';

export const Wrapper = styled.div`
	max-width: 768px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;

	.wrapper {
		margin-top: 90px;
		.top {
			.icon-logo {
				width: 28px;
				img {
					width: 100%;
				}
			}
		}
		.login-wrapper {
			margin-top: 20px;
			h1 {
				font-size: 26px;
				font-weight: 700;
				line-height: 140%;
			}
		}
		.input-wrap {
			margin-top: 26px;
			.input-box {
				margin-top: 10px;
				input {
					width: 100%;
					height: 52px;
					border-radius: 14px;
					border: 1px solid #eee;
					background: #fff;
					box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
					box-sizing: border-box;
					padding: 18px;
					font-size: 13px;
					font-weight: 400;
					line-height: normal;
					&::placeholder {
						font-size: 13px;
						font-weight: 400;
						opacity: 0.3;
					}
				}
			}
		}
		.login-btn {
			border-radius: 16px;
			height: 56px;
			background: #000;
			margin-top: 20px;
			color: #fff;
			text-align: center;

			font-size: 13px;
			font-weight: 600;
			line-height: 54px;
			letter-spacing: -0.26px;
		}
	}
`;
