import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './common.css';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot, useRecoilValue } from 'recoil';
import Login from './views/Auth/Login';
import Home from './views/Home';
import Card from './views/Card';
import CreateAnnua from './views/Home/CreateAnnualLayer';
import MyAnnuals from './views/Home/MyAnnualsLayer';
import CalendarWrapper from './views/Home/CreateAnnualLayer/Calendar';

function App() {
	return (
		<>
			<Helmet>
				<title>
					{/* {site_name ? `${site_name} - Linkfy Dashboard` : 'Linkfy Dashboard'} */}
				</title>
			</Helmet>
			<RecoilRoot>
				<div className="App">
					<ToastContainer />
					<BrowserRouter>
						<Routes>
							<Route exact={true} path="/" element={<Home />} />
							{/* <Route exact={true} path="/create" element={<CreateAnnua />} /> */}
							{/* <Route exact={true} path="/myannuals" element={<MyAnnuals />} /> */}
							<Route exact={true} path="/card/:ID" element={<Card />} />
						</Routes>

						<Routes>
							<Route exact={true} path="/login" element={<Login />} />
						</Routes>
					</BrowserRouter>
				</div>
			</RecoilRoot>
		</>
	);
}

export default App;
