import axios from './defaultClient';

const nameSpace = '/api/v1/annuals';

export const GetAnnuals = async (args) => {
	return await axios.get(nameSpace + '/', {
		params: args,
	});
};

export const InsertAnnual = async (args) => {
	return await axios.post(nameSpace + '/', args);
};
