import styled from 'styled-components';

export const Wrapper = styled.div`
	.overlay {
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.35);
		position: fixed;
		top: 0;
		left: 0;
		transition: 0.2s;
		z-index: 100;
	}
	.as {
		position: absolute;
		top: calc(50% - 130px);
		left: calc(50% + 111px);
		z-index: 99999;
		cursor: pointer;
	}
	.react-calendar {
		width: 310px;
		margin: 0 auto;
		padding: 24px 25px 20px 25px;
		border-radius: 14px;
		border: 1px solid #eee;
		background: #fff;
		box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.02);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 999;
	}
	.react-calendar__month-view__weekdays {
		color: #666;
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
	}
	.react-calendar__navigation__label span {
		color: #000;
		font-size: 15px;
		font-weight: 700;
		line-height: normal;
	}
	.react-calendar__navigation__prev2-button,
	.react-calendar__navigation__next2-button {
		display: none;
	}
	.react-calendar__tile--active {
		border-radius: 8px;
		background: #111;
	}
	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		border-radius: 8px;
		background: #111;
	}
	.react-calendar__tile {
		padding: 0;
		width: 30px;
		height: 30px;
		margin-top: 10px;
	}
	.react-calendar__navigation {
		height: unset;
		display: block;
		text-align: center;
		position: relative;
	}
	.react-calendar__navigation button {
		font-size: 18px;
	}
	/* .react-calendar__navigation::before {
		content: '오늘';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		color: #000;
		font-size: 12px;
		font-weight: 400;
		line-height: normal;
		border-radius: 70px;
		border: 1px solid #e0e0e0;
		background: #fff;
		padding: 5px 10px;
	} */
	/* .react-calendar__navigation::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 18px;
		height: 18px;
		background-image: url(/images/icon-calendar-close.svg);
	} */
`;
