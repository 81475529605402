import BottomSheet from '../../../components/BottomSheet';
import { useState } from 'react';
import { ContentWrapper, Wrapper } from './styled';
import { BtnWrapper } from './styled';
import CalendarWrapper from '../CreateAnnualLayer/Calendar';
import { toast } from 'react-toastify';

const ShareLayer = ({
	active,
	toggle,
	user,
	sharToggle,
	annualText,
	items,
	allAnnuals,
}) => {
	const [tab, setTab] = useState(0);

	const handleCopy = () => {
		navigator.clipboard.writeText(`${window.location.href}card/${user?.ID}`);
		// alert('Text copied to clipboard!');
		toast.success('コピーしました。');
	};

	return (
		<>
			<BottomSheet
				active={active}
				toggle={toggle}
				height={'unset'}
				auto={'hidden'}
			>
				<Wrapper>
					<div className="bar" onClick={toggle}></div>
					<div className="top">
						<div className="title">
							<img src="/images/icon_share2.svg" alt="공유아이콘" />
							<p>名刺を共有する</p>
						</div>
					</div>
					<div className="copy-box">
						<p>{`${window.location.href}card/${user?.ID}`}</p>
						<div onClick={handleCopy} className="copy">
							<span>コピー</span>
							<img src="/images/icon-copy.svg" alt="복사 아이콘" />
						</div>
					</div>
					<BtnWrapper
						onClick={() => {
							toggle();
						}}
					>
						<div className="btn">閉める</div>
					</BtnWrapper>
				</Wrapper>
			</BottomSheet>
		</>
	);
};

export default ShareLayer;
